import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import { App } from './blocks/App'

const rootElement = document.getElementById('root')

{
  rootElement &&
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    )
}
