import React, { FC, Dispatch, SetStateAction, useState } from 'react'
import { calculator } from '../../scripts'

interface FormState {
  price: string
  rent: string
  years: string
  rate: string
}

interface FormProps {
  setFormData: React.Dispatch<React.SetStateAction<FormState>>
  setSendPopupOpen: Dispatch<SetStateAction<boolean>>
}

export const Form: FC<FormProps> = ({ setFormData, setSendPopupOpen }) => {
  const sumDefault = '2900000'
  const rentDefault = '200000'

  const [sum, setSum] = useState(sumDefault)
  const [rent, setRent] = useState(rentDefault)

  const values = calculator(Number(sum === '' ? sumDefault : sum), Number(rent === '' ? rentDefault : rent))

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setFormData({
      price: sum,
      rent: rent,
      years: values.year.toString(),
      rate: values.percent.toString(),
    })
    setSendPopupOpen(true)
  }

  return (
    <form className='calculator-form'>
      <div className='input-group'>
        <label className='input-title' htmlFor='cost'>
          Стоимость объекта недвижимости
        </label>
        <div className='input'>
          <input
            id='cost'
            type='number'
            className='input-field'
            placeholder={sum === '' ? sumDefault : sum}
            onChange={e => setSum(e.target.value)}
            required
          />
          <div className='input-field__correction'>
            <div className='input-field__correction_line'></div>
            <span className='input-field__correction_text'>AED</span>
          </div>
        </div>
      </div>
      <div className='input-group'>
        <label className='input-title' htmlFor='sum'>
          Сумма сдачи в аренду (в год)
        </label>
        <div className='input'>
          <input
            id='cost'
            type='number'
            className='input-field'
            placeholder={rent === '' ? rentDefault : rent}
            onChange={e => setRent(e.target.value)}
            required
          />
          <div className='input-field__correction'>
            <div className='input-field__correction_line'></div>
            <span className='input-field__correction_text'>AED</span>
          </div>
        </div>
      </div>
      <div className='calculator-calculations'>
        <p className='calculator-calculations__title'>Срок окупаемости объекта</p>
        <p className='calculator-calculations__text'>{values.year} Лет</p>
      </div>
      <div className='calculator-calculations'>
        <p className='calculator-calculations__title'>Годовая доходность</p>
        <p className='calculator-calculations__text'>{values.percent} %</p>
      </div>
      <button onClick={handleSubmit} className='calculator-form_submit'>
        Отправить результаты на email
      </button>
    </form>
  )
}
