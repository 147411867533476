import React from 'react'

import { SocialList } from './SocialList'

interface HeaderDekstopProps {
  scrollToSection: (sectionId: string) => void
}

export const HeaderDekstop: React.FC<HeaderDekstopProps> = ({ scrollToSection }) => {
  return (
    <div className='header-block header-block_dekstop'>
      <ul className='header-nav__list'>
        <li className='header-nav__item' onClick={() => scrollToSection('#info')}>
          Информация
        </li>
        <li className='header-nav__item' onClick={() => scrollToSection('#calculator')}>
          Калькулятор
        </li>
        <li className='header-nav__item' onClick={() => scrollToSection('#contacts')}>
          Контакты
        </li>
      </ul>
      <SocialList />
      <a
        href='https://wa.me/79851710899?text=Добрый%20день,%20пожалуйста,%20перезвоните%20мне'
        target='blank'
        className='header-social__btn'
      >
        Обратный звонок
      </a>
    </div>
  )
}
