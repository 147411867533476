import React from 'react'
import call from '../../assets/images/footer/call.svg'
import youTube from '../../assets/images/youTube.svg'
import whatsApp from '../../assets/images/whatsApp.svg'
import map from '../../assets/images/footer/map.png'
import mapMobile from '../../assets/images/footer/mapMobile.png'

export const Footer = () => {
  return (
    <footer className='footer' id='contacts'>
      <div className='footer-container'>
        <h2 className='footer__title'>Контакты</h2>
        <ul className='footer-list'>
          <li className='footer-item'>
            <a href='tel:+79851710899' className='footer-item__link'>
              <img src={call} alt='call' className='footer-item__img' />
              <p className='footer-item__text'>
                Мы доступны круглосуточно
                <br />
                по телефону: +7&nbsp;(985)&nbsp;171-08-99
              </p>
            </a>
          </li>
          <li className='footer-item'>
            <a href='https://wa.me/79851710899' target='blank' className='footer-item__link'>
              <img src={whatsApp} alt='whatsApp' className='footer-item__img' />
              <p className='footer-item__text'>А так же в Whatsapp</p>
            </a>
          </li>
          <li className='footer-item'>
            <a href='https://www.youtube.com/@Alexeevinvest/videos' target='blank' className='footer-item__link'>
              <img src={youTube} alt='youTube' className='footer-item__img' />
              <p className='footer-item__text'>
                Больше информации и обзоров
                <br />
                недвижимости на YouTube-канале
              </p>
            </a>
          </li>
        </ul>
        <p className='footer-subtitle'>Офис находится по адресу: UAE, Dubai, Business Central Towers</p>
        <img src={map} alt='map' className='footer__map footer__map_dekstop' />
        <img src={mapMobile} alt='mapMobile' className='footer__map footer__map_mobile' />
      </div>
    </footer>
  )
}
