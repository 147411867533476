import { images } from '../assets/images/opportunities'

const imageTitles: string[] = ['Binghatti', 'Tiger', 'Sobha', 'Damac', 'Ellington', 'Emaar']

interface GalleryItem {
  title: string
  img: string
}

const imageKeys = Object.keys(images) as (keyof typeof images)[]

export const gallery: GalleryItem[] = imageTitles.map((title, index) => ({
  title: title,
  img: images[imageKeys[index]],
}))
