import React from 'react'
import youTube from '../../assets/images/youTube.svg'
import whatsApp from '../../assets/images/whatsApp.svg'

export const SocialList = () => {
  return (
    <ul className='header-social__list'>
      <li className='header-social__item'>
        <a href='https://www.youtube.com/@Alexeevinvest/videos' target='blank' className='header-social__link'>
          <img src={youTube} alt='youTube' className='header-social__img' />
        </a>
      </li>
      <li className='header-social__item'>
        <a href='https://wa.me/79851710899' target='blank' className='header-social__link'>
          <img src={whatsApp} alt='whatsApp' className='header-social__img' />
        </a>
      </li>
    </ul>
  )
}
