import React, { Dispatch, SetStateAction, FC, useState } from 'react'
import classNames from 'classnames'
import close from '../../assets/images/close.svg'
import { sendEmail } from '../../action/api'

interface FormState {
  price: string
  rent: string
  years: string
  rate: string
}

type PopupProps = {
  sendPopupOpen: boolean
  setSendPopupOpen: Dispatch<SetStateAction<boolean>>
  formData: FormState
}

export const PopupSendEmail: FC<PopupProps> = ({ sendPopupOpen, setSendPopupOpen, formData }) => {
  const [email, setEmail] = useState('')

  const updatedFormData = { ...formData, email: email }

  const handleSubmit = () => {
    sendEmail(updatedFormData)
    setSendPopupOpen(false)
  }

  return (
    <div className={classNames('send', { send_open: sendPopupOpen })}>
      <div className='send-container'>
        <img src={close} alt='close' className='send__close' onClick={() => setSendPopupOpen(false)} />
        <div className='send-block'>
          <p className='send__title'>Введите вашу почту</p>
          <input
            type='email'
            className='send-input'
            onChange={e => setEmail(e.target.value)}
            placeholder='example.pub@gmail.com'
            required
          />
          <button className='calculator-form_submit' onClick={handleSubmit}>
            Отправить результаты на email
          </button>
        </div>
      </div>
    </div>
  )
}
