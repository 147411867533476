import React, { useRef } from 'react'
import logo from '../../assets/images/header/logo.svg'

import { HeaderDekstop } from './HeaderDekstop'
import { HeaderMobile } from './HeaderMobile'

export const Header = () => {
  const headerHeight = useRef<HTMLDivElement>(null)

  const scrollToSection = (sectionId: string) => {
    const section = document.querySelector(sectionId)
    if (section) {
      const offset = headerHeight.current?.clientHeight || 0
      const top = section.getBoundingClientRect().top + window.scrollY - offset

      window.scrollTo({
        top,
        behavior: 'smooth',
      })
    }
  }

  return (
    <header className='header' ref={headerHeight}>
      <div className='header-container'>
        <div className='header-main'>
          <img src={logo} alt='DUINVEST.PRO' className='header__logo' />
          <HeaderDekstop scrollToSection={scrollToSection} />
          <HeaderMobile scrollToSection={scrollToSection} />
        </div>
      </div>
    </header>
  )
}
