import React from 'react'
import background from '../../assets/images/head/head.jpg'
import background_mobile from '../../assets/images/head/head_mobile.jpg'
import { Button } from '../../components/Button'

export const Head = () => {
  return (
    <section className='head'>
      <img src={background} alt='background' className='head-background head-background_dekstop' />
      <img src={background_mobile} alt='background' className='head-background head-background_mobile' />
      <div className='head-container'>
        <h2 className='head__title'>За последний год цены на квартиры выросли на 15.9% на виллы на 16%</h2>
        <p className='head__subtitle'>
          Выберите из сотен объектов недвижимости, начиная от студий за 300 000 AED и до роскошных таунхаусов за 100
          миллионов AED.
        </p>
        <Button link='https://wa.me/79851710899?text=Добрый%20день,%20я%20хочу%20получить%20каталог%20с%20объектами%20недвижимости'>
          Получить PDF с объектами
        </Button>
      </div>
    </section>
  )
}
