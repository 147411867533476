import React from 'react'
import { Header } from '../Header'
import { Head } from '../Head'
import { Opportunities } from '../Opportunities'
import { Objects } from '../Objects'
import { Calculator } from '../Calculator'
import { Footer } from '../Footer'

export const App = () => {
  return (
    <div className='page'>
      <Header />
      <Head />
      <Opportunities />
      <Objects />
      <Calculator />
      <Footer />
    </div>
  )
}
