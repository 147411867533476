import React, { Dispatch, SetStateAction, FC } from 'react'
import classNames from 'classnames'
import info from '../../assets/images/info.svg'
import close from '../../assets/images/close.svg'

type PopupProps = {
  popupOpen: boolean
  setPopupOpen: Dispatch<SetStateAction<boolean>>
}

export const Popup: FC<PopupProps> = ({ popupOpen, setPopupOpen }) => {
  return (
    <div className={classNames('calculator-popup', { 'calculator-popup_open': popupOpen })}>
      <div className='calculator-popup__container'>
        <img src={close} alt='close' className='calculator-popup__close' onClick={() => setPopupOpen(false)} />
        <img src={info} alt='info' className='calculator-info__img' />
        <div className='calculator-popup__block'>
          <p className='calculator-popup__text'>DLD transfer fee: 4%</p>
          <p className='calculator-popup__text'>Административный сбор: 4000&nbsp;AED</p>
          <p className='calculator-popup__text'>Ежегодная индексация аренды: 5% (с 2021 по 2022)</p>
        </div>
      </div>
    </div>
  )
}
