import React from 'react'
import { gallery } from '../../helpers'

export const Opportunities = () => {
  return (
    <section className='opportunities'>
      <div className='opportunities-container'>
        <h2 className='opportunities__title'>Ваши возможности в Дубае</h2>
        <p className='opportunities__subtitle'>
          Выберите из сотен объектов недвижимости, начиная от студий за&nbsp;300&nbsp;000 AED и до роскошных таунхаусов
          за&nbsp;100&nbsp;миллионов&nbsp;AED.
        </p>
      </div>
      <ul className='opportunities-gallery'>
        {gallery.map(item => (
          <li key={item.img} className='opportunities-item'>
            <img src={item.img} alt={item.img} className='opportunities-item__img' />
            <p className='opportunities-item__title'>{item.title}</p>
          </li>
        ))}
      </ul>
    </section>
  )
}
