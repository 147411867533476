import React, { useState, useEffect, FC } from 'react'
import classNames from 'classnames'

import burger from '../../assets/images/header/burger.svg'
import close from '../../assets/images/header/close.svg'

import { SocialList } from './SocialList'

interface HeaderMobileProps {
  scrollToSection: (sectionId: string) => void
}

export const HeaderMobile: FC<HeaderMobileProps> = ({ scrollToSection }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    show
      ? ((document.body.style.overflow = 'hidden'), (document.body.style.left = '0'))
      : ((document.body.style.overflow = ''), (document.body.style.left = ''))
  }, [show])

  const handleClick = (): void => {
    setShow((state: boolean) => !state)
  }

  const handleScroll = (sectionId: string): void => {
    scrollToSection(sectionId)
    setShow((state: boolean) => !state)
  }

  return (
    <div className='header-block header-block_mobile'>
      <SocialList />
      <button className='header-burger' onClick={handleClick}>
        <img src={burger} alt='burger' className='header-burger__img' />
      </button>
      <div className={classNames('sidebar', { sidebar_show: show })}>
        <div className='sidebar-container'>
          <div className='sidebar-head'>
            <SocialList />
            <button className='header-burger' onClick={handleClick}>
              <img src={close} alt='close' className='header-burger__img' />
            </button>
          </div>
          <ul className='header-nav__list'>
            <li className='header-nav__item' onClick={() => handleScroll('#info')}>
              Информация
            </li>
            <li className='header-nav__item' onClick={() => handleScroll('#calculator')}>
              Калькулятор
            </li>
            <li className='header-nav__item' onClick={() => handleScroll('#contacts')}>
              Контакты
            </li>
          </ul>
          <a
            href='https://wa.me/79851710899?text=Добрый%20день,%20пожалуйста,%20перезвоните%20мне'
            target='blank'
            className='header-social__btn'
          >
            Обратный звонок
          </a>
        </div>
      </div>
    </div>
  )
}
