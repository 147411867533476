interface FormSendProps {
  email: string
  price: string
  rent: string
  years: string
  rate: string
}

const url = 'https://api.duinvest.pro/send-email'

export const sendEmail = async ({ email, price, rent, years, rate }: FormSendProps) => {
  const data = {
    email_to: email,
    apartment_price: price,
    rent_price: rent,
    years: years,
    income_rate: rate,
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    if (!response.ok) {
      throw new Error('Request failed')
    }

    const responseData = await response.json()
    console.log(responseData)
  } catch (error: any) {
    console.error('Error:', error.message)
  }
}
