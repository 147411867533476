import React, { useState, useEffect } from 'react'
import info from '../../assets/images/info.svg'

import { Form } from './Form'
import { Popup } from './Popup'
import { PopupSendEmail } from './PopupSendEmail'

export const Calculator = () => {
  const [popupOpen, setPopupOpen] = useState(false)
  const [sendPopupOpen, setSendPopupOpen] = useState(false)
  const [formData, setFormData] = useState({
    price: '',
    rent: '',
    years: '',
    rate: '',
  })

  useEffect(() => {
    sendPopupOpen
      ? ((document.body.style.overflow = 'hidden'), (document.body.style.left = '0'))
      : ((document.body.style.overflow = ''), (document.body.style.left = ''))
  }, [sendPopupOpen])

  return (
    <section className='calculator' id='calculator'>
      <div className='calculator-container'>
        <h2 className='calculator__title'>Калькулятор инвестиций</h2>
        <Form setFormData={setFormData} setSendPopupOpen={setSendPopupOpen} />
        <div className='calculator-info' onClick={() => setPopupOpen(true)}>
          <img src={info} alt='info' className='calculator-info__img' />
          <p className='calculator-info__title'>Посмотреть, используемые в рассчетах усредненные параметры</p>
        </div>
        <Popup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
      </div>
      <PopupSendEmail sendPopupOpen={sendPopupOpen} setSendPopupOpen={setSendPopupOpen} formData={formData} />
    </section>
  )
}
