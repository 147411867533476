// const math = require('mathjs')
import { cumsum } from 'mathjs'

export const calculator = (purchase_price, rent_price) => {
  // Define initial data
  // const purchase_price = 1668000 // Purchase price of the property
  // const rent_price = 73000 // Rent price in the first year
  const index_rate = 0.05 // Indexation rate of rent per year
  const years = 300 // Number of years

  // Calculate yearly rent with indexation
  const rent_prices = new Array(years).fill(0)
  rent_prices[0] = rent_price
  for (let i = 1; i < years; i++) {
    rent_prices[i] = rent_prices[i - 1] * (1 + index_rate)
  }

  // Calculate payback of the property
  const cumulative_rent = cumsum(rent_prices)
  let payback_year
  for (let i = 0; i < cumulative_rent.length; i++) {
    if (cumulative_rent[i] >= purchase_price) {
      payback_year = i + 1
      break
    }
  }

  // Calculate average return
  const avg_return = (cumulative_rent[payback_year - 1] / (payback_year * purchase_price)) * 100

  let percent
  if (isNaN(avg_return)) percent = 0
  else percent = avg_return.toFixed(2)

  let year
  if (payback_year === undefined) year = 1
  else year = payback_year

  return { year, percent }
}
