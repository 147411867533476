import React from 'react'
import classNames from 'classnames'

type ButtonComponentProps = {
  children: JSX.Element | string
  className?: string
  link: string
}

export const Button: React.FC<ButtonComponentProps> = ({ children, className, link }) => {
  return (
    <a href={link} target='blank' className={classNames('button', { [`${className}`]: className })}>
      {children}
    </a>
  )
}
