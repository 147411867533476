import React from 'react'
import background from '../../assets/images/objects/background.jpg'
import backgroundMobile from '../../assets/images/objects/backgroundMobile.jpg'
import objects from '../../assets/images/objects/objects.jpg'
import objectsMobile from '../../assets/images/objects/objectsMobile.jpg'
import { Button } from '../../components/Button'

export const Objects = () => {
  return (
    <section className='objects' id='info'>
      <img src={background} alt='background' className='objects-background objects-background_dekstop' />
      <img src={backgroundMobile} alt='background' className='objects-background objects-background_mobile' />
      <div className='objects-container'>
        <h2 className='objects__title'>Мы предлагаем вам широкий спектр объектов для инвестиций</h2>
        <p className='objects__subtitle'>
          От инвестиций в жильё для собственного проживания до инвестиций в объекты, предназначенные для сдачи в аренду
          или просто под рост&nbsp;цен. Мы работаем напрямую с застройщиками, что позволяет нам предложить вам лучшие
          условия.
        </p>
        <Button link='https://wa.me/79851710899?text=Добрый%20день,%20я%20хочу%20получить%20каталог%20с%20объектами%20недвижимости'>
          Получить PDF с объектами
        </Button>
        <img src={objects} alt='objects' className='objects-img objects-img_dekstop' />
        <img src={objectsMobile} alt='objectsMobile' className='objects-img objects-img_mobile' />
      </div>
    </section>
  )
}
